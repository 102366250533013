* {
	margin: 0;
	box-sizing: border-box;
	font-family: "Nunito", sans-serif;
    font-display: swap;
}
html {
	scroll-behavior: smooth;
}
a {
	text-decoration: none;
	color: inherit;
}
body {
	min-height: 100vh;
	width: 100%;
	background: #003e29ed;
	color: #eee;
}
#root { display: flex; flex-direction: column; min-height: 100vh; }
main { display: flex; flex-grow: 1; flex-direction: column; }
.tlo {
	z-index: -1;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-image: url(./Obrazy/tlo1920.jpg);
    background-image: image-set("./Obrazy/tlo1920.webp" 1x);
    background-image: -webkit-image-set(url(./Obrazy/tlo1920.webp) 1x);
	background-image: -moz-image-set(url(./Obrazy/tlo1920.webp) 1x);
	background-image: -o-image-set(url(./Obrazy/tlo1920.webp) 1x);
	background-image: -ms-image-set(url(./Obrazy/tlo1920.webp) 1x);
	background-size: cover;
	background-position: center center;
}
header {
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 2;
	padding: 20px 0;
    height: 70px;
	background: #fff;
    color: #0c0c0c;
    box-shadow: 0 0 6px 0 #111;
}
nav {
    gap: 30px;
    display: flex;
    align-items: center;
}
nav a {
    color: #333;
    font-size: 1.15rem;
    font-weight: 700;
    text-shadow: 1px 1px 3px #aaa;
    letter-spacing: 0.5px;
    transition: all .4s ease;
}
nav a:hover {
    letter-spacing: 2px;
}
nav a.active { color: #0b5f26; text-shadow: 1px 1px 3px #aaa; font-weight: 800; }
.navKontakt {
    background: crimson;
    color: #eee;
    padding: 5px 20px;
    text-shadow: none;
    border-radius: 16px;
    box-shadow: 0 0 6px 1px #aaa; }
.logo {
    background-color: white;
    background-image: url(Obrazy/logo.jpg);
    background-image: image-set("./Obrazy/logo.webp" 1x);
    background-image: -webkit-image-set(url(./Obrazy/logo.webp) 1x);
	background-image: -moz-image-set(url(./Obrazy/logo.webp) 1x);
	background-image: -o-image-set(url(./Obrazy/logo.webp) 1x);
	background-image: -ms-image-set(url(./Obrazy/logo.webp) 1x);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 165%;
    aspect-ratio: 211 / 95;
    cursor: pointer;
}
footer {
	width: 100%;
	background: #0c0c0c;
}
.intro {
    background: #003e29ed;
    display: flex;
    flex-grow: 1;
    align-items: center;
}
.glownaIntro {
    min-height: calc(100vh - 70px);
}
.introLewa h1, .cowelderLewa h1,  .introLewaGasiq h1 {
    text-align: left;
    letter-spacing: 2px;
    color: #f5f5f5;
    text-shadow: 1px 1px 3px #1c1c1c;
    font-size: 2.5rem;
}
.introLewa p, .introLewaGasiq p, .cowelderLewa p {
    color: #efefef;
    font-size: 1.4rem;
    text-shadow: 1px 1px 3px #1c1c1c;
    letter-spacing: 0.5px;
    text-align: justify;
    margin: 1rem 0 1.5rem;
    line-height: 2.4rem;
    font-weight: 600;
}
.introLewaGasiq p {
    font-size: 1.2rem;
    line-height: 2rem;
}
.introLewaGasiq ul {
    list-style-type: none;
    padding-left: 0;
}
.introLewaGasiq li {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 20px;
    letter-spacing: 0.5px;
    text-shadow: 1px 1px 3px #222;
}
.introLewaGasiq li:nth-child(1) b { color: crimson; font-weight: 800; }
.introLewaGasiq li:nth-child(2) b { color: dodgerblue; font-weight: 800; }
.introPrawa, .introPrawaGasiq {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 40%;
}
.introPrawaGasiq {
    min-width: 360px;
    width: 60%;
    max-width: 500px;
    aspect-ratio: 540 / 689;
}

.wrapperSrodek {
    max-width: 1600px;
    height: 100%;
    margin: 0 auto;
    padding: 80px 60px 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.5rem;
}
.col {
    display: flex;
    flex-direction: column;
}
.row {
    display: flex;
    flex-direction: row;
}
.wrapperHeader { 
    max-width: 1600px;
    margin: 0 auto;
	width: 100%;
    height: 100%;
    display: flex;
    padding: 0 55px;
    align-items: center;
    justify-content: space-between;
}
.wrapperIntro {
    max-width: 1600px;
    height: 100%;
    margin: 0 auto;
    padding: 40px 60px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.5rem;
    gap: 100px;
}
.dwaPrzyciski {
    display: flex;
    gap: 20px;
    margin: 20px 0;
}
button {
    padding: 8px 14px;
    border-radius: 14px;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    width: fit-content;
    min-width: 180px;
    font-weight: 800;
    letter-spacing: 1px;
    transition: all .4s ease;
    box-shadow: 0 0 10px 0 #0c0c0c;
}
button:hover {
    letter-spacing: 2px;
}
.primaryBtn {
    background: #1c1c1c;
    color: #ccc;
}
.secondaryBtn {
    background: goldenrod;
    color: #1c1c1c;
}
.zakresUslug, .kontakt {
    background: #ddddddf0;
    display: flex;
    align-items: center;
    color: #333;
}
section h1 {
    font-weight: 800;
    letter-spacing: 1px;
    text-shadow: 1px 1px 3px #666;
    text-align: center;
}
section h3 {
    font-size: 1.2rem;
    text-shadow: 1px 1px 3px #777;
    letter-spacing: 0.5px;
    text-align: center;
    max-width: 700px;
}
section hr {
    width: 250px;
    margin: 1.2rem 0;
    height: 5px;
    border: none;
    background: #fff;
    box-shadow: 0 0 6px 0 #cbcbcb;
    border-radius: 5px;
}
.uslugi {
    margin-top: 5rem;
    gap: 80px;
    flex-wrap: wrap;
    justify-content: center;
}
.usluga {
    width: 100%;
    max-width: 420px;
    background: #1c1c1c;
    border-radius: 6px;
    padding: 55px 20px 20px;
    position: relative;
    box-shadow: 0 0 10px 0 #333;
}
.usluga h3 {
    font-size: 1.4rem;
    letter-spacing: 1px;
    max-width: 250px;
    text-align: left;
    color: #eee;
    text-shadow: 1px 1px 3px #111;
}
.usluga span { font-size: 1rem; letter-spacing: 0.5px; display: block; text-align: justify; margin-top: 0.75rem; color: #bbb; }
.uslugaObrazek {
    position: absolute;
    left: 10%;
    top: -40px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #0d533b;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px 0 #161616, inset 0 0 8px 0 #1c1c1c00;
}
.uslugaObrazek svg {
    scale: 1.7;
    fill: #d9d9d9;
    filter: drop-shadow(2px 4px 6px #111);
}
.dystrybucja {
    background: linear-gradient(84deg, #003e29f0, rgb(32 93 72 / 90%));
    display: flex;
    align-items: center;
}
.dystrybucjaWpis {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 40px;
    text-align: justify;
    background: #e1e1e1;
    box-shadow: 0 0 10px 0 #111;
    transition: all .4s ease;
}
.dystrybucjaWpis:hover {
    transform: translateY(-15px);
    scale: 1.05;
    background: #fff;
    cursor: pointer;
}
.dystrybucjaObraz {
    width: 100%;
    height: 130px;
    background-size: cover;
    background-position: center center;
    border-radius: 8px;
    box-shadow: inset 0 0 10px 0 #333;
    margin-bottom: 1rem;
}
.dystrybucjaTekst h4 {
    font-size: 2.3rem;
    color: #333333;
    font-weight: 800;
    text-shadow: 1px 1px 3px #555353;
    letter-spacing: 2px;
    text-align: center;
    margin-bottom: 0.5rem;
}
.dystrybucjaTekst p {
    font-size: 1.1rem;
    color: #333;
    font-weight: 600;
    text-shadow: 1px 1px 3px #777;
    padding: 0 10px;
}
.dystrybucja .row { gap: 50px; margin: 60px 0 0;}
.produktyGasiq {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    margin: 50px 0;
}
.produktGasiq {
    width: 100%;
    max-width: 400px;
    min-width: 250px;
    height: -moz-fit-content;
    height: fit-content;
    padding: 20px;
    background: #fff;
    display: flex;
    gap: 20px;
    align-items: center;
    box-shadow: 0 0 10px 0 #111;
    border-radius: 10px;
    transition: all .4s ease;
    color: #1c1c1c;
}
.produktGasiq:hover {
    scale: 1.05;
    color: dodgerblue;
    cursor: default;
}
.gasiqObraz {
    width: 260px;
    height: 130px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
}
.gasiqOpis {
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    letter-spacing: 1px;
    flex-grow: 1;
    width: 100%;
    text-shadow: 1px 1px 3px #888;
}
/* @keyframes wejscieSmooth {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
main {
    animation: wejscieSmooth 1s ease;
} */
.kontaktDane {
    background: #1c1c1c;
    color: #ccc;
    padding: 30px;
    line-height: 3rem;
    min-width: 350px;
    max-width: 400px;
    width: 100%;
    height: 487px;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.wrapperKontakt {
    width: 100%; gap: 0;
}
.wrapperKontakt iframe {
    border: none;
    width: 100%;
    height: 487px;
}
.kontaktDane svg {
    vertical-align: middle;
    margin-top: -6.5px;
    margin-right: 7px;
}
.cowelderPrawa {
    background: none;
    display: flex;
    aspect-ratio: 16 / 9;
    min-width: 340px;
    width: 100%;
    max-width: 700px;
    height: inherit;
    background-size: cover;
    background-position: center;
}
.cowelderWpis {
    display: flex;
    background: #003120f0;
    color: #ccc;
    -webkit-backdrop-filter: blur(9px);
    backdrop-filter: blur(9px);
    max-width: 1300px;
    box-shadow: 0 0 12px 0 #111;
    flex-direction: row-reverse;
}
.cowelderWpis:nth-child(even) { 
    flex-direction: row;
    background: #000000d9;
}
.cowelderObraz {
    width: 300px;
    min-width: 300px;
    aspect-ratio: 1 / 1;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
.cowelderTekst {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 2rem;
    justify-content: center;
    text-align: center;
    font-size: 1.2rem;
}
.cowelderTekst p {
    line-height: 2.2rem;
    text-align: justify;
}
.cowelderSzczegoly {
    background: #c3c3c3ca;
}
::-webkit-scrollbar { width: 10px; }
::-webkit-scrollbar-track { background: rgba(255,255,255,0.1); box-shadow: 0 0 4px 0 #111; }
::-webkit-scrollbar-track:hover { background: rgba(255,255,255,0.15); }
::-webkit-scrollbar-thumb { background: dodgerblue; box-shadow: 0 0 4px 0 #333; }
.cowelderLewa { max-width: 640px; }
footer .wrapperHeader {
    padding: 10px 50px;
    gap: 30px;
}
footer div.wrapperHeader div { text-align: center; }
.navKontakt.active { color: #f9ff00; }

/* OBRAZY */
.spartusImg {
    background-image: url(./Obrazy/spartus.jpg);
    background-image: image-set("./Obrazy/spartus.webp" 1x);
    background-image: -webkit-image-set(url(./Obrazy/spartus.webp) 1x);
    background-image: -moz-image-set(url(./Obrazy/spartus.webp) 1x);
    background-image: -o-image-set(url(./Obrazy/spartus.webp) 1x);
    background-image: -ms-image-set(url(./Obrazy/spartus.webp) 1x);
}
.gasiqImg {
    background-image: url(./Obrazy/gasiq.png);
    background-image: image-set("./Obrazy/gasiq.webp" 1x);
    background-image: -webkit-image-set(url(./Obrazy/gasiq.webp) 1x);
    background-image: -moz-image-set(url(./Obrazy/gasiq.webp) 1x);
    background-image: -o-image-set(url(./Obrazy/gasiq.webp) 1x);
    background-image: -ms-image-set(url(./Obrazy/gasiq.webp) 1x);
}
.migatronicImg {
    background-image: url(./Obrazy/migatronic.jpg);
    background-image: image-set("./Obrazy/migatronic.webp" 1x);
    background-image: -webkit-image-set(url(./Obrazy/migatronic.webp) 1x);
    background-image: -moz-image-set(url(./Obrazy/migatronic.webp) 1x);
    background-image: -o-image-set(url(./Obrazy/migatronic.webp) 1x);
    background-image: -ms-image-set(url(./Obrazy/migatronic.webp) 1x);
}
.cowelder3 {
    background-image: url(./Obrazy/cowelder3.jpg);
    background-image: image-set("./Obrazy/cowelder3.webp" 1x);
    background-image: -webkit-image-set(url(./Obrazy/cowelder3.webp) 1x);
    background-image: -moz-image-set(url(./Obrazy/cowelder3.webp) 1x);
    background-image: -o-image-set(url(./Obrazy/cowelder3.webp) 1x);
    background-image: -ms-image-set(url(./Obrazy/cowelder3.webp) 1x);
}
.cowelder4 {
    background-image: url(./Obrazy/cowelder4.jpg);
    background-image: image-set("./Obrazy/cowelder4.webp" 1x);
    background-image: -webkit-image-set(url(./Obrazy/cowelder4.webp) 1x);
    background-image: -moz-image-set(url(./Obrazy/cowelder4.webp) 1x);
    background-image: -o-image-set(url(./Obrazy/cowelder4.webp) 1x);
    background-image: -ms-image-set(url(./Obrazy/cowelder4.webp) 1x);
}
.cowelder2 {
    background-image: url(./Obrazy/cowelder2.jpg);
    background-image: image-set("./Obrazy/cowelder2.webp" 1x);
    background-image: -webkit-image-set(url(./Obrazy/cowelder2.webp) 1x);
    background-image: -moz-image-set(url(./Obrazy/cowelder2.webp) 1x);
    background-image: -o-image-set(url(./Obrazy/cowelder2.webp) 1x);
    background-image: -ms-image-set(url(./Obrazy/cowelder2.webp) 1x);
}
.centigmain {
    border: 1px solid #ccc;
    background-image: url(./Obrazy/centigmain.jpg);
    background-image: image-set("./Obrazy/centigmain.webp" 1x);
    background-image: -webkit-image-set(url(./Obrazy/centigmain.webp) 1x);
    background-image: -moz-image-set(url(./Obrazy/centigmain.webp) 1x);
    background-image: -o-image-set(url(./Obrazy/centigmain.webp) 1x);
    background-image: -ms-image-set(url(./Obrazy/centigmain.webp) 1x);
}
.centig1 {
    background-image: url(./Obrazy/centig1.jpg);
    background-image: image-set("./Obrazy/centig1.webp" 1x);
    background-image: -webkit-image-set(url(./Obrazy/centig1.webp) 1x);
    background-image: -moz-image-set(url(./Obrazy/centig1.webp) 1x);
    background-image: -o-image-set(url(./Obrazy/centig1.webp) 1x);
    background-image: -ms-image-set(url(./Obrazy/centig1.webp) 1x);
}
.centig2 {
    background-image: url(./Obrazy/centig2.jpg);
    background-image: image-set("./Obrazy/centig2.webp" 1x);
    background-image: -webkit-image-set(url(./Obrazy/centig2.webp) 1x);
    background-image: -moz-image-set(url(./Obrazy/centig2.webp) 1x);
    background-image: -o-image-set(url(./Obrazy/centig2.webp) 1x);
    background-image: -ms-image-set(url(./Obrazy/centig2.webp) 1x);
}
.centig3 {
    background-image: url(./Obrazy/centig3.jpg);
    background-image: image-set("./Obrazy/centig3.webp" 1x);
    background-image: -webkit-image-set(url(./Obrazy/centig3.webp) 1x);
    background-image: -moz-image-set(url(./Obrazy/centig3.webp) 1x);
    background-image: -o-image-set(url(./Obrazy/centig3.webp) 1x);
    background-image: -ms-image-set(url(./Obrazy/centig3.webp) 1x);
}

@media (min-width: 2559.98px) {
    .tlo {
        background-image: url(./Obrazy/tlo.jpg);
        background-image: image-set("./Obrazy/tlo.webp" 1x);
        background-image: -webkit-image-set(url(./Obrazy/tlo.webp) 1x);
        background-image: -moz-image-set(url(./Obrazy/tlo.webp) 1x);
        background-image: -o-image-set(url(./Obrazy/tlo.webp) 1x);
        background-image: -ms-image-set(url(./Obrazy/tlo.webp) 1x);
    }
}
@media (max-width: 1366.98px) {
    .uslugi { gap: 70px 30px; }
    .usluga { max-width: 380px; }
    .usluga h3 { font-size: 1.3rem; }
    .dystrybucjaTekst p, .usluga span { font-size: 0.95rem; }
    .tlo {
        background-image: url(./Obrazy/tlo1280.jpg);
        background-image: image-set("./Obrazy/tlo1280.webp" 1x);
        background-image: -webkit-image-set(url(./Obrazy/tlo1280.webp) 1x);
        background-image: -moz-image-set(url(./Obrazy/tlo1280.webp) 1x);
        background-image: -o-image-set(url(./Obrazy/tlo1280.webp) 1x);
        background-image: -ms-image-set(url(./Obrazy/tlo1280.webp) 1x);
    }
}
@media screen and (max-width: 1199.98px){
    .dystrybucja .row {
        flex-direction: column;
        gap: 10px;
    }
    .dystrybucjaWpis { flex-direction: row; align-items: center; }
    .dystrybucjaObraz { width: 300px; height: 200px; margin-right: 20px; }
    .dystrybucjaTekst { width: 100%; }
    .dystrybucjaTekst p { font-size: 1.1rem; }

}
@media screen and (max-width: 1141px){
    .wrapperIntro { gap: 30px; }
    .wrapperKontakt { gap: 0; }
    section h1, .introLewa h1, .cowelderLewa h1, .introLewaGasiq h1 { font-size: 2rem; }
    .usluga { max-width: 400px; }
    .usluga h3 { font-size: 1.1rem; }
    .usluga span { font-size: 0.9rem; }
    .dystrybucjaTekst p { font-size: 1.1rem; }
    .cowelderTekst { font-size: 1rem; }
}
@media (max-width: 991.98px) { 
    .wrapperIntro { gap: 30px; flex-direction: column;}
    .usluga { max-width: unset; }
    .cowelderLewa { max-width: unset; }
    .usluga h3 { font-size: 1.4rem; }
    .dystrybucjaTekst p, .usluga span { font-size: 1rem; }
    .cowelderTekst p { line-height: 2rem; }
    .wrapperKontakt { gap: 0; flex-direction: row;}
    .kontaktDane { font-size: 1.2rem; min-width: 280px; width: unset; padding: 30px 10px; }
}
.menuMobilne { display: none; }
@media (max-width: 899.98px) {
    .cowelderTekst p { line-height: 1.8rem; }
    .usluga h3 { font-size: 1.3rem; }
    .dystrybucjaTekst p, .usluga span { font-size: 0.95rem; }
    section h1, .introLewa h1, .cowelderLewa h1, .introLewaGasiq h1 { font-size: 1.75rem; }
    section h3, .introLewa p, .introLewaGasiq p, .cowelderLewa p { font-size: 1.1rem; }
    .wrapperHeader { padding-left: 40px; padding-right: 35px; }
    .wrapperIntro, .wrapperSrodek { padding: 80px 40px; }
    .cowelderObraz { min-width: 250px; }
    .introPrawa { display: none; }
    button { min-width: unset; }
    .cowelderPrawa, .introPrawaGasiq { min-width: unset; width: 100%; }
    /* MENU */
    .menuMobilne { display: block; min-width: unset; font-size: 1.25rem; cursor: pointer; }
    nav {
        position: fixed;
        top: 70px; right: 0; bottom: 0;
        width: 200px;
        padding: 2rem;
        flex-direction: column;
        line-height: 2rem;
        align-items: flex-start;
        background: #1c1c1c;
        transform: translateX(100%);
        transition: all .4s ease;
    }
    nav a { color: #ccc; text-shadow: 1px 1px 3px #000; }
    nav a.active { color: #ffc800; text-shadow: 1px 1px 3px #000; }
    .navKontakt { background: none; padding: 0; text-shadow: 1px 1px 3px #000; border-radius: none; box-shadow: none; }
    .navKontakt.active { color: #ffc800; }
    .wysuniete { transform: none; }
    .menuMobilne { width: 33px; height: 22px; position: relative; }
    .menuMobilne span {
        display: block;
        width: 33px;
        height: 4px;
        position: absolute;
        background: #333;
        border-radius: 4px;
        transform-origin: center;
        top: 50%;
        transition: all .4s cubic-bezier(0.77,0.2,0.05,1.0);
    }
    .menuMobilne span:nth-child(2) {
        top: calc(50% - 8px);
    }
    .menuMobilne span:nth-child(3) {
        top: calc(50% + 8px);
    }
    .menuMobilneOtwarte span {
        opacity: 1;
        transform: rotate(405deg);
        background: goldenrod;
        top: 50%;
    }
    .menuMobilneOtwarte span:nth-child(3) {
        opacity: 0;
        scale: 0.1, 0.1;
        transform: rotate(0deg);
        top: 50%;
    }
    .menuMobilneOtwarte span:nth-child(2) { transform: rotate(-405deg); top: 50%; }
}
@media (max-width: 767.98px) {
    .cowelderTekst p { line-height: 1.6rem; }
}
@media screen and (max-width: 699px){
    .wrapperIntro { padding: 40px 20px; }
    .wrapperSrodek { padding: 80px 20px; }
    footer .wrapperHeader { padding-left: 20px; padding-right: 20px; }
    .wrapperHeader { padding: 0 20px; }
    .dystrybucjaObraz { width: 200px; height: 150px; margin-right: 10px; }
    .cowelderLewa p { line-height: 1.8rem; }
    .cowelderObraz { min-width: 200px; }
    /* FOOTER */
    footer { font-size: 0.9rem; }
    footer .wrapperHeader { flex-direction: column;}
    .wrapperKontakt { flex-direction: column; }
    .kontaktDane { max-width: unset; width: 100%; font-size: 1.2rem;
        min-width: 280px;
        padding: 20px 10px;
        line-height: 1.6rem;
        height: unset;
    }
}
@media (max-width: 575.98px) { 
    .dystrybucjaTekst h4, .usluga h3 { font-size: 1.1rem; }
    .dystrybucjaTekst p, .usluga span { font-size: 0.9rem; }
    .dwaPrzyciski { flex-direction: column; align-items: center; }
    .produktyGasiq { gap: 20px; }
    .produktGasiq { max-width: unset; min-width: unset; padding: 10px; gap: 10px; }
    .gasiqObraz { width: 160px; height: 100px; }
    button { min-width: 180px; }
    .cowelderWpis { width: 100%; }
    .cowelderTekst h2 { font-size: 1.1rem; }
    .cowelderTekst p { font-size: 0.9rem; }
    .cowelderTekst { padding: 1rem; }
    .cowelderObraz { min-width: 150px; }
    .tlo {
        background-image: url(./Obrazy/tlo500.jpg);
        background-image: image-set("./Obrazy/tlo500.webp" 1x);
        background-image: -webkit-image-set(url(./Obrazy/tlo500.webp) 1x);
        background-image: -moz-image-set(url(./Obrazy/tlo500.webp) 1x);
        background-image: -o-image-set(url(./Obrazy/tlo500.webp) 1x);
        background-image: -ms-image-set(url(./Obrazy/tlo500.webp) 1x);
        background-position-y: bottom;
    }
}
@media (max-width: 475.98px) {
    footer .wrapperHeader, .wrapperHeader { padding-left: 10px; padding-right: 10px; }
    .wrapperSrodek { padding: 80px 10px; }
    .dystrybucjaWpis { flex-direction: column; }
    .dystrybucjaObraz { width: 100%; height: 120px; margin-right: 0; }
    section h3, .introLewa p, .introLewaGasiq p, .cowelderLewa p { line-height: 1.5rem; }
    .gasiqOpis { font-size: 0.9rem; letter-spacing: 0; }
    .cowelderWpis { flex-direction: column; }
    .cowelderWpis:nth-child(even) { flex-direction: column; }
    .cowelderObraz { width: 100%; height: 200px; background-size: cover; }
}